import { API } from "aws-amplify";
import { listCheckInArchives } from "../graphql/customQueries";
import moment from 'moment';

export default async (email, date) => {
    try {
        const {
            data: {
                listCheckInArchives: { items: archives },
            },
        } = await API.graphql({
            query: listCheckInArchives,
            variables: {
                email,
                timestamp: {
                    between: [moment(date).startOf("day"), moment(date).endOf("day")],
                },
                limit: 99999,
            },
        });
        return archives;
    } catch (ex) {
        console.warn("list archives failed", ex);
    }
};
