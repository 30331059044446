import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFieldArray } from "react-hook-form";
import theme from "../common/theme";
import { ButtonBase } from "./Buttons";
import Divider from "./Divider";
import FormEmergencyContact from "./FormEmergencyContact";
import FormTextInput from "./FormTextInput";
import Row from "./Row";
import { H3 } from "./Typography";
import FormTextArea from "./FormTextArea";

export default ({ name = "erpAreas.items", control, errors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: "keyId",
    })

    const fieldProps = { control, errors }

    return (
        <div style={{ paddingRight: '1rem', overflow: 'auto' }}>
            <H3>ERP Areas</H3>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }
                return (
                    <div key={item.keyId} style={{display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                        <Row>
                            <FormTextInput
                                {...fieldProps}
                                rules={{ required: true }}
                                placeholder="Enter ERP Area Name"
                                label="ERP Area Name"
                                name={`${name}.${index}.name`}
                                containerStyle={{ flex: 0 }}
                            />
                            <ButtonBase onClick={onDelete}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                        </Row>
                        <FormTextArea {...fieldProps} name={`${name}.${index}.contactNotes`} label="Notes" placeholder="Enter notes for this ERP Area" />
                        <FormEmergencyContact
                            {...fieldProps}
                            name={`${name}.${index}.emergencyContacts.items`}
                        />
                    </div>
                )
            })}
            <Divider />
            <ButtonBase onClick={() => append({})} style={{ color: theme.palette.red, fontWeight: 'bold' }}>+ Add additional ERP Area</ButtonBase>
        </div>
    )
}