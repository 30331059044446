import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFieldArray } from "react-hook-form";
import styled from "styled-components";
import theme from "../common/theme";
import { ButtonBase } from "./Buttons";
import FormPhoneNumber from "./FormPhoneNumber";
import FormSelectNotifyBy from "./FormSelectNotifyBy";
import FormTextInput from "./FormTextInput";
import Row from "./Row";
import { orderBy } from 'lodash';
import { P } from "./Typography";

const AddEmergencyContactButton = styled(ButtonBase)`
    align-self: stretch;
    background: transparent;
    border: 1px dashed ${theme.palette.red};
    color: ${theme.palette.red};
    padding: 0.5rem;
    font-weight: bold;
    margin: 1rem 0;
`

export default ({ name = "emergencyContacts.items", control, errors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: 'keyId'
    },)
    const fieldProps = { control, errors, rules: { required: { value: true, message: "Required" } } }
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }

                return (
                    <Row key={item.keyId}>
                        <FormTextInput
                            {...fieldProps}
                            placeholder="Enter #"
                            label="Priority"
                            name={`${name}.${index}.priority`}
                            containerStyle={{ flex: 0, width: '100px' }}
                        />
                        <FormTextInput
                            {...fieldProps}
                            placeholder="Enter Name"
                            label="Name"
                            name={`${name}.${index}.name`}
                            containerStyle={{ flex: 1 }}
                        />
                        <FormPhoneNumber
                            {...fieldProps}
                            placeholder="Enter Phone Number"
                            label="Phone Number"
                            name={`${name}.${index}.phone`}
                            defaultCountry="CA"
                        />
                        <FormTextInput
                            {...fieldProps}
                            placeholder="Enter Email"
                            label="Email"
                            name={`${name}.${index}.email`}
                            containerStyle={{ flex: 1 }}
                            rules={{
                                required: true,
                                pattern: {
                                    value: re,
                                    message: "Invalid email"
                                }
                            }}
                        />
                        <FormSelectNotifyBy name={`${name}.${index}.notifyBy`} label="NotifyBy" {...fieldProps} rules={{ required: false }} />
                        <ButtonBase onClick={onDelete}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                    </Row>
                )
            })}
            { !fields.length && <P>No emergency contacts yet.  Please create one below.</P>}
            <AddEmergencyContactButton onClick={() => append({})}>+ Add Emergency Contact</AddEmergencyContactButton>
        </div>
    )
}