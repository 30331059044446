import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArchive, faBell, faCheckSquare, faCoffee, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import awsConfig from "./aws-exports";
import { reset } from "./common/slices/globalActions";
import { refreshUserData, selectUser } from "./common/slices/user";
import Layout from "./components/Layout";
import ScreenHome from "./components/ScreenHome";
import ScreenManageUsers from "./components/ScreenManageUsers";
import ScreenPrivacy from "./components/ScreenPrivacy";
import { getUserSettings } from "./slices/app";
import store from "./store";
import AccessDenied from "./components/AccessDenied";
import "moment-duration-format";
import ScreenLogin from "./components/ScreenLogin";
import ModalConfirm from "./components/ModalConfirm";
import ScreenForgotPassword from "./components/ScreenForgotPassword";
import ScreenForgotPasswordConfirm from "./components/ScreenForgotPasswordConfirm";
import ScreenNewPassword from "./components/ScreenNewPassword";
import useProtectedRoute from "./hooks/useProtectedRoute";
import useMustAcceptTerms from "./hooks/useMustAcceptTerms";
import ScreenAcceptTerms from "./components/ScreenAcceptTerms";


library.add(faCheckSquare, faCoffee, faBell, faArchive, faInfoCircle);
Amplify.configure(awsConfig);

function App() {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <Routes>
                    <Route path="/auth/login" element={<ScreenLogin />} />
                    <Route path="/auth/new-password" element={<ScreenNewPassword />} />
                    <Route path="/auth/forgot-password" element={<ScreenForgotPassword />} />
                    <Route path="/auth/forgot-password-confirm" element={<ScreenForgotPasswordConfirm />} />
                    <Route path="/privacy" element={<ScreenPrivacy />} />
                    <Route path="/terms-of-use" element={<ScreenAcceptTerms />} />
                    <Route path="/" element={<BeSafe />}>
                        <Route path="/" element={<ScreenHome />} />
                        <Route path="/admin" element={<ScreenManageUsers />} />
                        <Route path="*" element={<div>Not Found</div>} />
                    </Route>
                </Routes>
            </Provider>
        </BrowserRouter>
    );
}

const BeSafe = ({ user }) => {
    const nav = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const u = useSelector(selectUser);

    useProtectedRoute();
    // useMustAcceptTerms(u);

    useEffect(() => {
        store.dispatch(refreshUserData);
        store.dispatch(getUserSettings(user?.attributes?.email));
    }, [user]);

    if (u && u.role === "WORKER") {
        return <AccessDenied />;
    }

    const signOut = async () => {
        Auth.signOut();
        nav('/auth/login');
        store.dispatch(reset());
    }


    return (
        <Layout user={user} signOut={() => setConfirm(true)}>
            <Outlet />
            {
                confirm &&
                <ModalConfirm
                    title="Sign Out Confirmation"
                    description="Are you sure you want to sign out?  Any active work-alone session will end, and you will no longer be able to monitor workers for safety."
                    confirmButtonTitle="Sign Out"
                    onClose={() => setConfirm(false)}
                    onConfirm={signOut}
                />
            }
        </Layout>
    );
};

export default App;
