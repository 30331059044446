import { faBell, faGear, faList, faLocationPin, faMapPin, faSignOut, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logoImg from '../assets/logo.svg';
import { selectNotifications } from "../common/slices/checkIn";
import { selectIsRemoteWorker } from "../common/slices/user";
import theme from '../common/theme';
import { selectModalState, setModalState } from "../slices/app";
import { H1, H2 } from './Typography';


const TopNavContainer = styled.div`
    display: flex;
    border-bottom: 1px solid rgba(41, 44, 84, 1);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0 2rem;
    padding-top: 1rem;
    gap: 1rem;
`

const TopNavButton = styled((props) => {
    let navigate = useNavigate();
    let match = useMatch(props.path);
    return (
        <H2
            {...props}
            onClick={() => navigate(props.path)}
            style={{
                color: match ? 'white' : '#606060',
                borderBottom: match ? `2px solid ${theme.palette.red}` : "2px solid #606060"
            }}
        />
    )
})`
    margin: 0;
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    cursor: pointer;
    transition: border-color 0.125s linear;
    line-height: 42px;

    @media (max-width: 768px) {
        display: ${props => props.hiddenMobile ? 'none' : undefined}
    }
`

const Logo = styled.img`
    height: 2.5rem;
    padding: 0;
    margin: 0 3rem 0 0;
    align-self: flex-start;
`

const NotificationCount = styled.span`
    background-color: ${theme.palette.red};
    color: #fff;
    padding: 0.25rem;
    border-radius: 3rem;
    border: 1px solid ${theme.palette.red};
    position: relative;
    top: -1.25rem;
    right: 0;
    display: inline-block;
    font-size: 12px;
`;

const NotificationButton = styled(({ user, ...props }) => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const show = useSelector(selectModalState("SIDEBAR"));

    return (
        <H2 {...props} onClick={() => dispatch(setModalState({ modalName: "SIDEBAR", show: !!!show }))}>
            <FontAwesomeIcon icon={faBell} size="sm" />
            {notifications.length !== 0 && <NotificationCount>{notifications.length}</NotificationCount>}
        </H2>
    )
})`
    font-size: 26px;
    cursor: pointer;
    padding: 0 2rem;

    @media (max-width: 768px) {
        display: ${props => props.hiddenMobile ? 'none' : undefined}
    }
`

const Dropdown = styled.div`
    position: relative;
    min-width: 200px;

    @media (max-width: 768px) {
        min-width: 0;
    }
`

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: white;
    z-index: 10;
    flex-direction: column;
    padding: 1rem 0;
    `

const DropdownButton = styled(H2)`
    flex: 1;
    display: block;
    color: ${theme.palette.blue};
    padding: 0.5rem 2rem;
    margin: 0;
    cursor: pointer;
    
    &:hover {
        background-color: ${theme.palette.lightBlue}
        }
        `

const SignoutButton = styled(H2)`
        cursor: pointer;
        padding: 0.5rem 0;
`

const Divider = styled.hr`
    flex: 1;
    width: 85%;
    border-top: 1px solid ${theme.palette.border};
    margin-top: 1rem;
    margin-bottom: 1rem;
`

const TopNavMenu = styled((props) => {
    const [display, setDisplay] = useState('none');
    const navigate = useNavigate();
    return (
        <Dropdown onMouseEnter={() => setDisplay('flex')} onMouseLeave={() => setDisplay('none')}>
            <H1 {...props} ><FontAwesomeIcon icon={faGear} /> &nbsp; Menu</H1>
            <DropdownContent style={{ display }}>
                <DropdownButton onClick={() => navigate('/privacy', { replace: true })}><FontAwesomeIcon icon={faUserSecret} /> &nbsp; Privacy Policy</DropdownButton>
                <Divider />
                <DropdownButton onClick={() => props.signOut()}><FontAwesomeIcon icon={faSignOut} /> &nbsp; Sign Out</DropdownButton>
            </DropdownContent>
        </Dropdown>
    )
})`
    font-size: 26px;
`


const TopNav = ({ user, signOut }) => {
    const isRemoteWorker = useSelector(selectIsRemoteWorker);

    return (
        <TopNavContainer>
            <Logo src={logoImg} />
            <TopNavButton path="/" hiddenMobile><FontAwesomeIcon icon={faLocationPin} /> &nbsp; Map</TopNavButton>
            {
                isRemoteWorker === false &&
                <>
                    <TopNavButton key={'manageUsersButton'} path="/admin" hiddenMobile><FontAwesomeIcon icon={faList} /> &nbsp; Admin</TopNavButton>
                    <div style={{ flex: 1 }}></div>
                    <NotificationButton key={'notificationsButton'} user={user} hiddenMobile />
                    <SignoutButton onClick={signOut}><FontAwesomeIcon icon={faSignOut} /> &nbsp; Sign Out</SignoutButton>
                </>
            }
        </TopNavContainer>
    )
}

export default TopNav;
