import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Pmb, P, H3 } from "./Typography";
import { forwardRef } from "react";
import { ErrorMessage } from '@hookform/error-message';
import theme from "../common/theme";
import AsyncSelect from 'react-select/async';

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // margin: 1rem 0;
`

export const UncontrolledFormTextInput = forwardRef(({ errors, label, containerStyle, loadOptions, disabled, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            {label && <H3 noMargin>{label}</H3>}
            <AsyncSelect
                defaultOptions
                cacheOptions
                isDisabled={disabled}
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        fontSize: '1rem',
                        borderRadius: 0,
                        border: 'none',
                        cursor: 'pointer',
                        border: `1px solid #ccc`,
                        padding: '0.12rem',
                    }),
                    option: (styles) => ({
                        ...styles,
                        color: '#000',
                    }),
                    menuPortal: (styles) => ({ ...styles, zIndex: 1200 })
                }} {...rest} />
            {errors && <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: 'red' }}>{message}</P>}
            />}
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormTextInput {...fieldProps} {...rest} />
            )}
        />
    );
};
