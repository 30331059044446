import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../common/assets/feature_image.jpeg';
import { login } from '../common/slices/auth';
import { Container, FormContainer, Input, LoginContainer, Logo } from './Auth';
import { ButtonBase, DarkButton } from './Buttons';
import { Footer } from './Layout';
import { H1 } from './Typography';


const ScreenLogin = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const onSubmit = async (values) => {
        const user = await dispatch(login(values.email, values.password));
        if (user) {
            const { challengeName } = user;
            switch (challengeName) {
                case "NEW_PASSWORD_REQUIRED":
                    nav('/auth/new-password', { state: { email: values.email } });
                    break;
                default:
                    nav('/');

            }
        } else {
            setError('email', { message: 'Invalid username or password' })
            setError('password', { message: 'Invalid username or password' })
        }
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid, isDirty },
        watch,
    } = useForm()

    const fieldProps = { control, errors };

    return (
        <Container>
            <Logo src={logo} />
            <LoginContainer>
                <FormContainer>
                    <H1>Login</H1>
                    <Input name="email" placeholder="Username" {...fieldProps} />
                    <Input name="password" placeholder="Password" {...fieldProps} type="password" />
                    <DarkButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting} >Sign In</DarkButton>
                    <ButtonBase style={{ color: 'white' }} onClick={() => nav('/auth/forgot-password')}>Forgot your password?</ButtonBase>
                </FormContainer>
            </LoginContainer>
            <Footer />
        </Container>
    )
}

export default ScreenLogin;
