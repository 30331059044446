/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDirections = /* GraphQL */ `
  query GetDirections($input: DirectionsInput) {
    getDirections(input: $input) {
      geocoded_waypoints {
        geocoder_status
        partial_match
        place_id
        types
        __typename
      }
      routes {
        waypoint_order
        overview_polyline
        warnings
        __typename
      }
      __typename
    }
  }
`;
export const getSummary = /* GraphQL */ `
  query GetSummary($input: SummaryInput) {
    getSummary(input: $input) {
      userCount
      checkInCount
      checkInCountByStatus {
        status
        count
        __typename
      }
      __typename
    }
  }
`;
export const getShiftReports = /* GraphQL */ `
  query GetShiftReports($input: ShiftReportInput) {
    getShiftReports(input: $input) {
      startDate
      endDate
      reports {
        report
        __typename
      }
      __typename
    }
  }
`;
export const getArchive = /* GraphQL */ `
  query GetArchive($email: String!, $date: AWSDateTime!) {
    getArchive(email: $email, date: $date) {
      email
      date
      locationHistory {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArchives = /* GraphQL */ `
  query ListArchives(
    $email: String
    $date: ModelStringKeyConditionInput
    $filter: ModelArchiveFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArchives(
      email: $email
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($userId: String!) {
    getCheckIn(userId: $userId) {
      userId
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $userId: String
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckIns(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        timestamp
        status
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInsByStatus = /* GraphQL */ `
  query CheckInsByStatus(
    $status: Status!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        timestamp
        status
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInsByCompanyId = /* GraphQL */ `
  query CheckInsByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        timestamp
        status
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckInArchive = /* GraphQL */ `
  query GetCheckInArchive($email: String!, $timestamp: AWSDateTime!) {
    getCheckInArchive(email: $email, timestamp: $timestamp) {
      email
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      status
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      companyId
      __typename
    }
  }
`;
export const listCheckInArchives = /* GraphQL */ `
  query ListCheckInArchives(
    $email: String
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckInArchives(
      email: $email
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInArchiveByEmailAndStatus = /* GraphQL */ `
  query CheckInArchiveByEmailAndStatus(
    $email: String!
    $timestampStatus: ModelCheckInArchiveByTimestampAndStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInArchiveByEmailAndStatus(
      email: $email
      timestampStatus: $timestampStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInArchiveByTimestamp = /* GraphQL */ `
  query CheckInArchiveByTimestamp(
    $timestamp: AWSDateTime!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInArchiveByTimestamp(
      timestamp: $timestamp
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInArchiveByStatus = /* GraphQL */ `
  query CheckInArchiveByStatus(
    $status: Status!
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInArchiveByStatus(
      status: $status
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInArchiveByStatusAndCompanyId = /* GraphQL */ `
  query CheckInArchiveByStatusAndCompanyId(
    $status: Status!
    $companyId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInArchiveByStatusAndCompanyId(
      status: $status
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const checkInArchiveByCompanyIdAndStatus = /* GraphQL */ `
  query CheckInArchiveByCompanyIdAndStatus(
    $companyId: String!
    $timestampStatus: ModelCheckInArchiveByCompanyIdAndStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInArchiveByCompanyIdAndStatus(
      companyId: $companyId
      timestampStatus: $timestampStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        timestamp
        status
        companyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      logo
      emergencyResponsePhoneNumber
      contactNotes
      erpAreas {
        nextToken
        __typename
      }
      utcOffset
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getERPArea = /* GraphQL */ `
  query GetERPArea($id: ID!) {
    getERPArea(id: $id) {
      id
      name
      contactInfo
      contactNotes
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        createdAt
        updatedAt
        __typename
      }
      users {
        nextToken
        __typename
      }
      emergencyContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listERPAreas = /* GraphQL */ `
  query ListERPAreas(
    $id: ID
    $filter: ModelERPAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listERPAreas(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const erpByCompanyId = /* GraphQL */ `
  query ErpByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelERPAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    erpByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmergencyContact = /* GraphQL */ `
  query GetEmergencyContact($id: ID!) {
    getEmergencyContact(id: $id) {
      id
      priority
      name
      email
      phone
      notifyBySMS
      notifyByEmail
      notificationTypes
      type
      companyId
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmergencyContacts = /* GraphQL */ `
  query ListEmergencyContacts(
    $id: ID
    $filter: ModelEmergencyContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmergencyContacts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        priority
        name
        email
        phone
        notifyBySMS
        notifyByEmail
        notificationTypes
        type
        companyId
        erpAreaId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emergencyContactsByCompanyId = /* GraphQL */ `
  query EmergencyContactsByCompanyId(
    $companyId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencyContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emergencyContactsByCompanyId(
      companyId: $companyId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        name
        email
        phone
        notifyBySMS
        notifyByEmail
        notificationTypes
        type
        companyId
        erpAreaId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emergencyContactsByErpAreaId = /* GraphQL */ `
  query EmergencyContactsByErpAreaId(
    $erpAreaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencyContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emergencyContactsByErpAreaId(
      erpAreaId: $erpAreaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        name
        email
        phone
        notifyBySMS
        notifyByEmail
        notificationTypes
        type
        companyId
        erpAreaId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emergencyContactsByUserId = /* GraphQL */ `
  query EmergencyContactsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencyContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emergencyContactsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priority
        name
        email
        phone
        notifyBySMS
        notifyByEmail
        notificationTypes
        type
        companyId
        erpAreaId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIndividualShift = /* GraphQL */ `
  query GetIndividualShift($id: ID!, $shiftDate: AWSDate!) {
    getIndividualShift(id: $id, shiftDate: $shiftDate) {
      id
      email
      scheduleId
      expiry
      name
      description
      shiftDate
      startTime
      endTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIndividualShifts = /* GraphQL */ `
  query ListIndividualShifts(
    $id: ID
    $shiftDate: ModelStringKeyConditionInput
    $filter: ModelIndividualShiftFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIndividualShifts(
      id: $id
      shiftDate: $shiftDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const individualShiftByEmail = /* GraphQL */ `
  query IndividualShiftByEmail(
    $email: String!
    $shiftDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIndividualShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    individualShiftByEmail(
      email: $email
      shiftDate: $shiftDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const individualShiftByScheduleId = /* GraphQL */ `
  query IndividualShiftByScheduleId(
    $scheduleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIndividualShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    individualShiftByScheduleId(
      scheduleId: $scheduleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInstallation = /* GraphQL */ `
  query GetInstallation($owner: String!, $id: String!) {
    getInstallation(owner: $owner, id: $id) {
      owner
      id
      deviceName
      expoPushToken
      appVersion
      brand
      modelName
      modelId
      productName
      totalMemory
      osName
      osVersion
      osBuildId
      __typename
    }
  }
`;
export const listInstallations = /* GraphQL */ `
  query ListInstallations(
    $owner: String
    $id: ModelStringKeyConditionInput
    $filter: ModelInstallationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstallations(
      owner: $owner
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        id
        deviceName
        expoPushToken
        appVersion
        brand
        modelName
        modelId
        productName
        totalMemory
        osName
        osVersion
        osBuildId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJourney = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      startAddress
      endAddress
      eta
      route {
        waypoint_order
        overview_polyline
        warnings
        __typename
      }
      riskLevel
      userId
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJourneys = /* GraphQL */ `
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startAddress
        endAddress
        eta
        riskLevel
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const journeysByUserId = /* GraphQL */ `
  query JourneysByUserId(
    $userId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journeysByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startAddress
        endAddress
        eta
        riskLevel
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMissedShift = /* GraphQL */ `
  query GetMissedShift($shiftId: ID!) {
    getMissedShift(shiftId: $shiftId) {
      shiftId
      email
      shiftDate
      shift {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        __typename
        shiftPattern
        startDate
        endDate
        __typename
      }
      status
      createdAt
      __typename
    }
  }
`;
export const listMissedShifts = /* GraphQL */ `
  query ListMissedShifts(
    $shiftId: ID
    $filter: ModelMissedShiftFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMissedShifts(
      shiftId: $shiftId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shiftId
        email
        shiftDate
        status
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const missedShiftByEmail = /* GraphQL */ `
  query MissedShiftByEmail(
    $email: String!
    $shiftDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMissedShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    missedShiftByEmail(
      email: $email
      shiftDate: $shiftDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shiftId
        email
        shiftDate
        status
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecurringShiftPattern = /* GraphQL */ `
  query GetRecurringShiftPattern($id: ID!) {
    getRecurringShiftPattern(id: $id) {
      id
      email
      scheduleId
      name
      description
      expiry
      startTime
      endTime
      startDate
      endDate
      shiftPattern
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecurringShiftPatterns = /* GraphQL */ `
  query ListRecurringShiftPatterns(
    $id: ID
    $filter: ModelRecurringShiftPatternFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRecurringShiftPatterns(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        scheduleId
        name
        description
        expiry
        startTime
        endTime
        startDate
        endDate
        shiftPattern
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recurringShiftPatternByEmail = /* GraphQL */ `
  query RecurringShiftPatternByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringShiftPatternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recurringShiftPatternByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        scheduleId
        name
        description
        expiry
        startTime
        endTime
        startDate
        endDate
        shiftPattern
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recurringShiftPatternByScheduleId = /* GraphQL */ `
  query RecurringShiftPatternByScheduleId(
    $scheduleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecurringShiftPatternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recurringShiftPatternByScheduleId(
      scheduleId: $scheduleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        scheduleId
        name
        description
        expiry
        startTime
        endTime
        startDate
        endDate
        shiftPattern
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShiftSchedule = /* GraphQL */ `
  query GetShiftSchedule($id: ID!) {
    getShiftSchedule(id: $id) {
      id
      email
      companyId
      name
      description
      expiry
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      recurringShiftPatterns {
        nextToken
        __typename
      }
      individualShifts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShiftSchedules = /* GraphQL */ `
  query ListShiftSchedules(
    $id: ID
    $filter: ModelShiftScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShiftSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        companyId
        name
        description
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shiftScheduleByEmail = /* GraphQL */ `
  query ShiftScheduleByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShiftScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftScheduleByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        companyId
        name
        description
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shiftSchedulesByCompanyId = /* GraphQL */ `
  query ShiftSchedulesByCompanyId(
    $companyId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShiftScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftSchedulesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        companyId
        name
        description
        expiry
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      email
      firstName
      lastName
      role
      avatar
      status
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        createdAt
        updatedAt
        __typename
      }
      checkIns {
        nextToken
        __typename
      }
      checkInProtocol
      shiftStart
      nextCheckIn
      installations {
        nextToken
        __typename
      }
      archive {
        nextToken
        __typename
      }
      phoneNumber
      contactNotes
      manualTimestamp
      satelliteDeviceId
      satelliteDeviceType
      satelliteDeviceSMS
      mode
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      excludeShiftReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByRole = /* GraphQL */ `
  query UsersByRole(
    $role: Role!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRole(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByStatus = /* GraphQL */ `
  query UsersByStatus(
    $status: Status!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByCompanyIdAndStatus = /* GraphQL */ `
  query UsersByCompanyIdAndStatus(
    $companyId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyIdAndStatus(
      companyId: $companyId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersBySatelliteId = /* GraphQL */ `
  query UsersBySatelliteId(
    $satelliteDeviceId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySatelliteId(
      satelliteDeviceId: $satelliteDeviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersBySatelliteSMS = /* GraphQL */ `
  query UsersBySatelliteSMS(
    $satelliteDeviceSMS: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySatelliteSMS(
      satelliteDeviceSMS: $satelliteDeviceSMS
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByMode = /* GraphQL */ `
  query UsersByMode(
    $mode: OperatingMode!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByMode(
      mode: $mode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByERPArea = /* GraphQL */ `
  query UsersByERPArea(
    $erpAreaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByERPArea(
      erpAreaId: $erpAreaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($email: String!, $name: String!) {
    getUserSettings(email: $email, name: $name) {
      email
      name
      value
      __typename
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $email: String
    $name: ModelStringKeyConditionInput
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettings(
      email: $email
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        name
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserTermsAcceptance = /* GraphQL */ `
  query GetUserTermsAcceptance($email: String!, $version: String!) {
    getUserTermsAcceptance(email: $email, version: $version) {
      email
      companyId
      version
      acceptedAt
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserTermsAcceptances = /* GraphQL */ `
  query ListUserTermsAcceptances(
    $email: String
    $version: ModelStringKeyConditionInput
    $filter: ModelUserTermsAcceptanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserTermsAcceptances(
      email: $email
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        companyId
        version
        acceptedAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userTermsAcceptanceByCompanyId = /* GraphQL */ `
  query UserTermsAcceptanceByCompanyId(
    $companyId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTermsAcceptanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTermsAcceptanceByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        companyId
        version
        acceptedAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userTermsAcceptanceByVersion = /* GraphQL */ `
  query UserTermsAcceptanceByVersion(
    $version: String!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTermsAcceptanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTermsAcceptanceByVersion(
      version: $version
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        companyId
        version
        acceptedAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userTermsAcceptanceByDate = /* GraphQL */ `
  query UserTermsAcceptanceByDate(
    $acceptedAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTermsAcceptanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTermsAcceptanceByDate(
      acceptedAt: $acceptedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        companyId
        version
        acceptedAt
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
