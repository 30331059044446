import { faArrowCircleUp, faBattery0, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faCircle, faLeaf, faPhone, faPlug, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { statuses } from '../common/constants';
import { selectActiveCheckIn, updateCheckIn } from '../common/slices/checkIn';
import { fetchUserEmergencyContacts, selectUserEmergencyContacts, updateUser } from '../common/slices/user';
import theme from '../common/theme';
import friendlyBatteryState from "../common/utils/friendlyBatteryState";
import friendlyProtocol from '../common/utils/friendlyProtocol';
import friendlyStatus from '../common/utils/friendlyStatus';
import isNotification from '../common/utils/isNotification';
import { selectLoading, setLoading } from '../slices/app';
import { clearArchive, listArchive, selectArchiveDate } from '../slices/archive';
import ArchiveDetails from './ArchiveDetails';
import Avatar from './Avatar';
import { ButtonBase, GreenButton, RedButton } from './Buttons';
import Details from './Details';
import Divider from './Divider';
import { ERPEmergencyContactList } from './ERP';
import LastUpdate from './LastUpdate';
import MapActiveCheckIn from "./MapActiveCheckIn";
import Modal from './Modal';
import ModalConfirm from './ModalConfirm';
import NextCheckIn from './NextCheckIn';
import Role from './Role';
import Row from './Row';
import S3ImageCheckIn from './S3ImageCheckIn';
import { EmptyTab, Tab, TabContainer, TabContent, TabsContainer } from './Tabs';
import { H1, H2, P, P2 } from './Typography';
import { Status, StatusLastUpdate } from './Status';
import LSDConverter from './LSDConverter';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;    
    color: white;
    overflow: hidden;
    `

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 0.25rem;
    min-width: 600px;
`

const Right = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0.5rem;

    @media (max-width: 768px) {
        display: none;
    }
`

const WorkerDetailsTab = ({ checkIn }) => (
    <TabContent tabId={0}>

        <H2>Details</H2>
        <Row>
            <Details title="Company" data={checkIn?.company?.name} />
            <Details title="Name" data={`${checkIn?.user?.firstName} ${checkIn?.user?.lastName}`} />
        </Row>
        <Row>
            <Details title="Phone Number" data={formatPhoneNumber(checkIn?.user?.phoneNumber)} />
            <Details title="Email / Username" data={checkIn?.user?.email} />
        </Row>
        {
            checkIn?.user?.contactNotes &&
            <Details title={`${checkIn?.user?.firstName}'s Notes`} data={checkIn?.user?.contactNotes} />
        }
        <Divider />

        <H2>Shift Information</H2>
        {
            checkIn?.status !== "OFF" ?
                <Row>
                    <Details title="Shift Start (YYYY/MM/DD)" data={moment(checkIn?.user?.shiftStart).format("YYYY/MM/DD [-] h:mm a")} />
                    <Details title="Shift Duration" data={moment.duration(moment().diff(moment(checkIn?.user?.shiftStart), 'm'), 'm').format("*h:mm [h]")} />
                </Row>
                :
                <P2 noMargin>{checkIn?.user?.firstName} is not on a shift, their last shift ended on {moment(checkIn?.user?.manualTimestamp).format("YYYY/MM/DD [at] h:mm a")}.</P2>
        }
        <H2>Location</H2>
        <LastUpdate checkIn={checkIn} />

        <H2>Check-In</H2>
        <Row>
            <Details title="Last Check-In" data={`${moment(checkIn?.user?.manualTimestamp).format("h:mm a")} - ${moment(checkIn?.user?.manualTimestamp).fromNow()}`} />
            <Details title="Check-In Frequency" data={friendlyProtocol(checkIn?.user?.checkInProtocol)} />
        </Row>
        <NextCheckIn checkIn={checkIn} />
    </TabContent>
)

const EmergencyContactsTab = ({ checkIn }) => {
    const contacts = useSelector(selectUserEmergencyContacts);

    if (checkIn?.user?.erpArea) {
        return (
            <TabContent tabId={1}>
                <Row>
                    <Details title="Company" data={checkIn?.company?.name} />
                    <Details title="ERP Area" data={checkIn?.user?.erpArea?.name} />
                </Row>
                {
                    checkIn?.company?.contactNotes &&
                    <Details title={`${checkIn?.company?.name} Notes`} data={checkIn?.company?.contactNotes} />
                }
                {
                    checkIn?.user?.contactNotes &&
                    <Details title={`${checkIn?.user?.firstName} Notes`} data={checkIn?.user?.contactNotes} />
                }
                <H2>{checkIn?.user?.erpArea?.name} Contacts</H2>
                {
                    checkIn?.user?.erpArea?.contactNotes &&
                    <Details title={`${checkIn?.user?.erpArea?.name} Notes`} data={checkIn?.user?.erpArea?.contactNotes} style={{ marginBottom: '1rem' }} />
                }
                <ERPEmergencyContactList contacts={checkIn?.user?.erpArea?.emergencyContacts?.items} />
                {
                    contacts.length > 0 &&
                    <>
                        <Divider />
                        <H2>{checkIn?.user?.firstName}'s Contacts</H2>
                        <ERPEmergencyContactList contacts={contacts} />
                    </>
                }
                {!checkIn?.user?.erpArea?.emergencyContacts?.items?.length && <P style={{ fontSize: '0.75rem', margin: 0, padding: 0 }}>{checkIn?.user?.erpArea?.contactInfo}</P>}
            </TabContent>
        )
    } else {
        return (
            <TabContent tabId={1}>
                <P noMargin><FontAwesomeIcon icon={faPhone} /> {checkIn?.company?.emergencyResponsePhoneNumber}</P>
            </TabContent>
        )
    }

}

const TelemetryTab = ({ checkIn }) => {
    const speed = (checkIn?.location.speed * 3.6).toFixed(2)

    const renderBatteryStatus = () => {
        let resp;
        if (checkIn?.battery && checkIn?.user?.mode !== 'GPS') {
            const { batteryState, batteryLevel, lowPowerMode } = checkIn.battery;

            switch (batteryState) {
                case 1:
                    let icon, color = theme.palette.green;
                    if (batteryLevel < 0.1) { icon = faBattery0; color = theme.palette.red }
                    else if (batteryLevel < 0.25) { icon = faBatteryQuarter; color = theme.palette.yellow }
                    else if (batteryLevel >= 0.25 && batteryLevel < 0.75) { icon = faBatteryHalf; }
                    else if (batteryLevel >= 0.75 && batteryLevel < 0.90) { icon = faBatteryThreeQuarters }
                    else { icon = faBatteryFull }
                    resp = (
                        <P noMargin>
                            <FontAwesomeIcon icon={icon} color={color} />
                            &nbsp;
                            {(batteryLevel * 100).toFixed(2)}%
                            &nbsp;
                            {lowPowerMode && <FontAwesomeIcon icon={faLeaf} color={theme.palette.red} title="Low power mode active" />}
                        </P>
                    )
                    break;
                case 2:
                    resp = (<P noMargin><FontAwesomeIcon icon={faPlug} color={theme.palette.green} /> {(batteryLevel * 100).toFixed(2)}%</P>)
                    break;
                case 3:
                    resp = (<P noMargin><FontAwesomeIcon icon={faBatteryFull} color={theme.palette.green} /> Full</P>)
                    break;
                default:
                    break;
            }
        }

        return resp;
    }

    const renderHeading = () => {
        return <P noMargin>
            {checkIn?.location?.heading?.toFixed(3)} &deg; &nbsp;
            <FontAwesomeIcon
                icon={faArrowCircleUp}
                style={{ transform: `rotate(${checkIn?.location.heading}deg)`, transition: 'all 1s' }}
                title={`${checkIn?.location.heading?.toFixed(0)}\u00B0 Heading`}
            />
        </P>
    }

    return (
        <TabContent tabId={2}>
            <H2>Device Overview</H2>
            <Row>
                <Details title="Mode" data={checkIn?.user?.mode || "CELLULAR"} />
                {
                    checkIn?.user?.mode === "GPS" &&
                    <Details title="Device IMEI" data={checkIn?.user?.satelliteDeviceId} />
                }

            </Row>
            <Row>
                <Details title="Battery" data={renderBatteryStatus()} />
                <Details title="Battery Status" data={friendlyBatteryState(checkIn?.battery?.batteryState)} />

            </Row>
            <Divider />
            <H2>Navigation Data</H2>
            <Details title="GPS Location" data={`${checkIn?.location.lat}, ${checkIn?.location.lng}`} />
            <Row>
                <Details title="Heading" data={renderHeading()} />
                <Details title="Speed" data={`${speed} km/h`} />
            </Row>
            <Details title="Elevation" data={`${checkIn?.location.altitude?.toFixed(2)} meters`} />
            <LSDConverter checkIn={checkIn} />
        </TabContent>
    )
}

const ArchiveTab = ({ checkIn }) => {
    const dispatch = useDispatch();
    const archiveDate = useSelector(selectArchiveDate);
    const loading = useSelector(selectLoading);

    const onDateClick = async (date) => {
        if (!moment(date).isSame(archiveDate, 'day')) {
            dispatch(setLoading(true));
            await dispatch(listArchive(checkIn.userId, date));
            dispatch(setLoading(false));
        } else dispatch(clearArchive());
    }

    return (
        <TabContent tabId={3}>
            <P noMargin>Select a date on the calendar to view shift details.</P>
            <Calendar onChange={onDateClick} value={archiveDate} tileDisabled={() => loading} />
            <Divider />
            <ArchiveDetails />
        </TabContent>
    )

}

const ModalUserDetails = ({ show, onClose }) => {
    const checkIn = useSelector(selectActiveCheckIn);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [offShiftConfirm, setOffShiftConfirm] = useState(false);

    useEffect(() => {
        if (checkIn) {
            dispatch(fetchUserEmergencyContacts(checkIn.userId));
        }
    }, [checkIn])

    const onUpdateUser = (status) => async () => {
        const newStatus = status.split("_")[0] + '_OK';
        dispatch(setLoading(true));
        await dispatch(updateCheckIn(checkIn.userId, newStatus))
        await dispatch(updateUser({ email: checkIn.userId, status: status.split("_")[0], manualTimestamp: new Date() }))
        dispatch(setLoading(false));
    }



    const onUserDetailsClose = (e) => {
        dispatch(clearArchive());
        onClose(e);
    }

    if (!checkIn) {
        return null;
    }


    return (
        <Modal
            show={show}
            onClose={onUserDetailsClose}
            bodyStyle={{
                backgroundColor: theme.palette.blue,
                borderColor: theme.palette.blue,
                minHeight: '90%',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
            }}
            containerStyle={{
                alignItems: 'flex-start',
                paddingTop: '1rem'
            }}
        >
            <Container>
                <Row style={{ flex: 0 }}>
                    <H1 style={{ marginRight: 'auto' }}>Worker Details</H1>
                    <ButtonBase onClick={() => onUserDetailsClose()}><FontAwesomeIcon icon={faTimesCircle} color="white" size="2x" /></ButtonBase>
                </Row>
                <Divider />
                <Row style={{ alignItems: 'stretch' }}>

                    <Left>
                        <Row style={{ flex: 0 }}>
                            <Avatar avatar={checkIn?.user?.avatar} />
                            <div>
                                <Role role={checkIn?.user?.role} />
                                <H2 noMargin>{checkIn?.user?.firstName} {checkIn?.user?.lastName}</H2>
                            </div>
                        </Row>
                        <Row style={{ flex: 0, gap: 0, marginBottom: '1rem' }}>
                            <Status status={checkIn?.status} />
                            <StatusLastUpdate checkIn={checkIn} />
                        </Row>
                        <TabContainer>

                            <TabsContainer>
                                <Tab tabId={0}>Overview</Tab>
                                <Tab tabId={1}>Emergency Contacts</Tab>
                                <Tab tabId={2}>Telemetry</Tab>
                                <Tab tabId={3}>Archive</Tab>
                                <EmptyTab />
                            </TabsContainer>
                            <WorkerDetailsTab checkIn={checkIn} />
                            <EmergencyContactsTab checkIn={checkIn} />
                            <TelemetryTab checkIn={checkIn} />
                            <ArchiveTab checkIn={checkIn} />


                        </TabContainer>
                        <Divider style={{ marginTop: 'auto' }} />
                        {
                            isNotification(checkIn?.status) &&
                            <GreenButton onClick={onUpdateUser(checkIn?.status)} disabled={loading}>Acknowledge</GreenButton>
                        }


                        <RedButton onClick={async () => setOffShiftConfirm(true)} disabled={checkIn?.status === statuses.OFF || loading}>Set Off Shift</RedButton>
                    </Left>
                    <Right>
                        <MapActiveCheckIn checkIn={checkIn} />
                    </Right>
                </Row>

            </Container>
            {offShiftConfirm && <ModalConfirm
                title="Confirm Set Off Shift"
                description={`Are you sure you want to put ${checkIn?.user?.firstName} ${checkIn?.user?.lastName} off shift?`}
                confirmButtonTitle='Set Off Shift'
                onConfirm={() => {
                    dispatch(updateCheckIn(checkIn?.userId, statuses.OFF));
                    dispatch(updateUser({ email: checkIn?.userId, status: statuses.OFF, manualTimestamp: new Date() }));
                    setOffShiftConfirm(false)
                }}
                onClose={() => setOffShiftConfirm(false)} />}
        </Modal>
    )
}

export default ModalUserDetails