import { useEffect, useState } from 'react';
import { erpByCompanyId } from '../common/graphql/queries';
import QueryBuilder from '../common/service/QueryBuilder';
import FormSelectInput from './FormSelectInput';
import { useForm, useFormContext } from 'react-hook-form';

const listAll = new QueryBuilder(erpByCompanyId, "erpByCompanyId", null);
const FormSelectERP = ({ companyId, setValue, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(true);

    useEffect(() => {
        (async () => {
            const options = await loadOptions();
            setDefaultOptions(options);
            setValue("erpAreaId", options[0])
        })()
    }, [companyId])

    const loadOptions = async () => {
        listAll.setParams({ companyId });
        const { items } = await listAll.execute();
        return items.map(i => ({ value: i.id, label: i.name }))
    }

    return (
        <FormSelectInput name="erpAreaId" placeholder="ERP Area" label="ERP Area" defaultOptions={defaultOptions} loadOptions={loadOptions} cacheOptions={companyId} {...props} />
    )
}

export default FormSelectERP;