import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectActiveCheckIn, setActiveCheckIn } from '../common/slices/checkIn';
import { selectModalState, setModalState } from '../slices/app';
import { RedButton } from './Buttons';
import MapCheckIn from './MapCheckIn';
import MapControls from './MapControls';
import ModalUserDetails from './ModalUserDetails';
import { useNavigate } from 'react-router-dom';
import { H1, P } from './Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import theme from '../common/theme';
import logo from '../assets/logo.svg';

const Container = styled.div`
    background-color: white;
    border: 1px solid #fff;
    border-radius: 3rem;
    color: black;
    margin: 0 auto;
    width: 50%;
    padding: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`

const Logo = styled.img`
    width: 200px;
`

const AccessDenied = () => {
    const nav = useNavigate();
    const logout = async () => {
        await Auth.signOut();
        nav('/auth/login')
    }

    return (
        <Container>
            <Logo src={logo} />
            <H1>Access Denied</H1>
            <P>You do not have permission to access this page.</P>
            <P>This area is restricted to supervisors and authorized personnel only.  If you believe this is an error or need access for a specific reason, please contact your supervisor.</P>
            <RedButton onClick={logout}>Logout</RedButton>
        </Container>
    )
}

export default AccessDenied;
