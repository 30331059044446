import { Controller } from "react-hook-form";
import styled from "styled-components";
import theme from "../common/theme";
import { H3, P } from "./Typography";
import { forwardRef } from "react";
import { ErrorMessage } from '@hookform/error-message';

const Input = styled.input`
    flex: 1;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
`

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const UncontrolledFormTextInput = forwardRef(({ errors, label, containerStyle, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            <H3 noMargin>{label}</H3>
            <Input
                ref={ref}
                {...rest}
            />
            {errors && <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: theme.palette.red }}>{message}</P>}
            />}
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormTextInput {...fieldProps} {...rest} />
            )}
        />
    );
};
