/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "beSafeRest",
            "endpoint": "https://13i3dh3qm3.execute-api.ca-central-1.amazonaws.com/test",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5ul5ph5f5vfmldawf5x5ydabee.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-dzjt4fu3ijcafmfawou2a6zrvq",
    "aws_cognito_identity_pool_id": "ca-central-1:44ca83c3-7640-4d25-86e5-ed8c5a0c20f7",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_omZwnlypD",
    "aws_user_pools_web_client_id": "3srli4uquk69grp0fja9t9ucfr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "besafeavatars172804-test",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
