import {
    Circle, Polygon, Polyline
} from "@react-google-maps/api";
import Map from "./Map";
import CheckInMarker from "./MarkerCheckIn";
import PolylineArchive from "./PolylineArchive";
import theme from "../common/theme";
import { useSelector } from "react-redux";
import { selectSettings } from "../slices/app";
import moment from 'moment';
import { useEffect, useRef } from "react";
import { useState } from "react";
import isOnShift from "../common/utils/isOnShift";
import isOnJourney from "../common/utils/isOnJourney";
import PointsOfInterest from "./PointsOfInterest";
import TrafficLayer from "./TrafficLayer";

const ProbabilityRadius = ({ checkIn }) => {
    const [radius, setRadius] = useState(0);
    let timeoutRef = useRef();
    let { location: { lat, lng, speed } } = checkIn

    const updateRadius = () => {
        if (speed < 1) { speed = 0.25 }
        const diff = moment().diff(moment(checkIn?.updatedAt), 'ms');
        setRadius(speed * (diff / 1000)); //m/s * seconds = meters
        timeoutRef.current = requestAnimationFrame(updateRadius);
    }

    useEffect(() => {
        updateRadius();
        return () => cancelAnimationFrame(timeoutRef.current);
    }, [checkIn])

    if (!isOnShift(checkIn.status) && !isOnJourney(checkIn.status)) {
        return null;
    }

    return (
        <>
            <Circle center={{ lat, lng }} radius={radius * 1.4} options={{
                strokeColor: theme.palette.green,
                strokeWeight: 2,
                fillColor: 'transparent',
                strokeOpacity: 0.8,
            }} />
            <Circle center={{ lat, lng }} radius={radius} options={{
                strokeColor: theme.palette.green,
                strokeWeight: 2,
                fillColor: theme.palette.green,
                fillOpacity: 0.15,
                strokeOpacity: 0.8,
            }} />
        </>
    )
}

function calculatePieCoordinates(origin, heading, distance, angle = 30) {
    if (!window.google?.maps?.geometry?.spherical?.computeOffset) {
        return []
    }

    // convert the origin point from lat-long to web mercator
    const originWebMercator = window.google.maps.geometry.spherical.computeOffset(origin, 0, heading);
    let points = [originWebMercator];  // array to store the points of the pie

    // convert heading and angle to radians
    const headingRadians = heading * (Math.PI / 180);
    const angleRadians = angle * (Math.PI / 180);
    for (let i = headingRadians - angleRadians / 2; i < headingRadians + angleRadians / 2; i += 0.01) {
        // calculate x and y using trigonometry
        const latlng = window.google.maps.geometry.spherical.computeOffset(originWebMercator, distance, i * 180 / Math.PI);
        points.push(latlng);
    }
    points.push(originWebMercator);
    return points;
}


const ProbabilityPolygon = ({ checkIn, angle = 45 }) => {
    const [state, setState] = useState({ coordinates: [] });
    const timeoutRef = useRef();
    let { location: { lat, lng, heading, speed }, updatedAt } = checkIn

    const updateRadius = () => {
        if (speed < 1) { speed = 0.25 }
        const distance = speed * (moment().diff(updatedAt, 'ms') / 1000);
        const coordinates = calculatePieCoordinates({ lat, lng }, heading, distance * 1.4, angle)
        const coordinates2 = calculatePieCoordinates({ lat, lng }, heading, distance * 1.7, angle * 1.5)
        setState({
            coordinates,
            coordinates2
        })
        timeoutRef.current = requestAnimationFrame(updateRadius);
    }

    useEffect(() => {
        updateRadius();
        return () => cancelAnimationFrame(timeoutRef.current);
    }, [checkIn])

    if (!isOnShift(checkIn.status) && !isOnJourney(checkIn.status)) {
        return null;
    }

    return (
        <>
            <Polygon path={state.coordinates2} options={{
                strokeColor: theme.palette.green,
                fillColor: 'transparent',
                strokeWeight: 2,
            }} />
            <Polygon path={state.coordinates} options={{
                strokeColor: theme.palette.green,
                fillColor: theme.palette.green,
                fillOpacity: 0.5,
                strokeWeight: 1,
            }} />
        </>
    )
}


const MapActiveCheckIn = ({ checkIn }) => {
    const { userDetailsMapType } = useSelector(selectSettings);
    return (
        <Map
            mapContainerStyle={{
                height: '100%',
            }}
            zoom={18}
            center={checkIn?.location}
            options={{
                disableDefaultUI: true,
                mapTypeId: 'hybrid', //userDetailsMapType?.id || 'roadmap'
            }}
        >
            <ProbabilityPolygon checkIn={checkIn} key="probabilityPolygon" />
            {/* <ProbabilityRadius checkIn={checkIn} key="probabilityRadius" /> */}
            <PolylineArchive key='polylineArchive' />
            <PointsOfInterest key="pointsOfInterest" />
            <CheckInMarker key='checkInMarker' checkIn={checkIn} />
            {/* <TrafficLayer key="traffic" /> */}
        </Map>
    )
};

export default MapActiveCheckIn;