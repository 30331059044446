import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../common/slices/auth';
import { acceptTerms, selectUser } from '../common/slices/user';
import theme from '../common/theme';
import { BlueButton } from './Buttons';
import { Footer } from './Layout';
import Row from './Row';
import FormCheckbox from "./FormCheckbox";
import { useForm } from "react-hook-form";
import { H1, H3, P } from "./Typography";
import styled from "styled-components";
import Divider from "./Divider";
import moment from "moment";
import logoImg from '../assets/logo.svg';


const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    margin-bottom: auto;
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
`

const Terms = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5rem 4rem;
    background-color: rgba(16, 18, 36, 1);
    max-height: calc(100vh - 24rem);
    overflow-y: scroll;

    p, li {
        font-size: 0.8rem;
    }

    ol {
        margin-bottom: 1rem;
    }
`

const Heading = styled(H3)`
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
`

const ViewHeader = styled.div`
    border-bottom: 1px solid ${theme.palette.border};
    padding: 1rem;
`

const Logo = styled.img`
    height: 2.5rem;
    border: none;
`

const TermsOfUse = ({ user }) => (
    <Terms>
        <Heading>Authorized User Terms of Use</Heading>
        <p>These Software Terms of Use ("<b>Terms of Use</b>") govern your use of the Be-Safe software (the "<b>Software</b>"), including all user manuals, technical manuals, and any other materials provided by Licensor, in printed, electronic, or other form, that describe the Software or its use or specifications (the "<b>Documentation</b>") provided to you ("<b>you</b>" or "<b>your</b>") for use pursuant to and subject to a Software as a Service Agreement (the "<b>Software Licence Agreement</b>") between Be Safe Inc. ("<b>Licensor</b>") and your employer or other person or entity who pays you for services ("<b>Licensee</b>").</p>
        <p>BY CHECKING THE "ACCEPT" BOX YOU: (i) REPRESENT THAT YOU ARE DULY AUTHORIZED BY LICENSEE TO ACCESS AND USE THE SOFTWARE; AND (ii) ACCEPT THESE AUTHORIZED USER TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT CHECK THE "ACCEPT" BOX AND YOU WILL HAVE NO LICENCE TO, AND MUST NOT ACCESS OR USE, THE SOFTWARE.</p>
        <p>Effective Date: <b>{moment().format('YYYY-MM-DD')}</b></p>
        <p>
            User: <b>{user.firstName} {user.lastName}</b><br />
            Company: <b>{user.company.name}</b>
        </p>
        <ol>
            <li>
                <b>License Grant.</b><br />
                <p>Subject to your strict compliance with these Terms of Use, Licensor hereby grants you a non-exclusive, non-transferable, non-sublicensable, limited licence to use the Software solely in accordance with the Documentation, as installed on the equipment provided by Licensee and for Licensee's internal business purposes. The foregoing licence will terminate immediately on the earlier to occur of:</p>
                <ol type="a">
                    <li>the expiration or earlier termination of the Software Licence Agreement between Licensor and Licensee; or</li>
                    <li>your ceasing to be authorized by Licensor to use the Software for any or no reason.</li>
                </ol>
            </li>
            <li>
                <b>Use Restrictions.</b><br />
                <p>You shall not, directly or indirectly:</p>
                <ol type="a">
                    <li>use the Software or Documentation except as set forth in Section 1;</li>
                    <li>copy the Software or Documentation, in whole or in part;</li>
                    <li>modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Software or any part thereof;</li>
                    <li>combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;</li>
                    <li>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;</li>
                    <li>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices included on or in the Software or Documentation, including any copy thereof;</li>
                    <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise provide any access to or use of the Software or any features or functionality of the Software, for any reason, to any other person or entity, including any subcontractor, independent contractor, affiliate, or service provider of Licensee, whether or not over a network and whether or not on a hosted basis, including in connection with the internet, web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud, or other technology or service;</li>
                    <li>use the Software or Documentation in, or in association with, the design, construction, maintenance, or operation of any hazardous environments or systems, including:</li>
                    <ol type="i">
                        <li>power generation systems;</li>
                        <li>aircraft navigation or communications systems, air traffic control systems, or any other transport management systems;</li>
                        <li>safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire, or other safety response systems; and</li>
                        <li>military or aerospace applications, weapons systems, or environments;</li>
                    </ol>
                    <li>use the Software or Documentation in violation of any law, regulation, or rule; or</li>
                    <li>use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service or any other purpose that is to the Licensor's commercial disadvantage.</li>
                </ol>
            </li>
            <li>
                <b>Compliance Measures.</b><br />
                <p>The Software may contain technological copy protection or other security features designed to prevent unauthorized use of the Software, including features to protect against use of the Software:</p>
                <ol type="a">
                    <li>beyond the scope of the licence granted to under Section 1;</li>
                    <li>prohibited under Section 2.</li>
                </ol>
                <p>You shall not, and shall not attempt to, remove, disable, circumvent, or otherwise create or implement any workaround to, any such copy protection or security features.</p>
            </li>
            <li>
                <b>Collection and Use of Information.</b><br />
                <p></p>
                <ol type="a">
                    <li>Licensor may, directly or indirectly through the services of others, collect and store information regarding use of the Software and about equipment on which the Software is installed or through which it otherwise is accessed and used, by means of (i) providing maintenance and support services and (ii) security measures included in the Software as described in Section 3.</li>
                    <li>You agree that the Licensor may use such information for any purpose related to any use of the Software by you, including but not limited to: (i) improving the performance of the Software or developing updates; and (ii) verifying compliance with the terms of this Agreement and enforcing Licensor's rights, including all intellectual property rights in and to the Software.</li>
                </ol>

            </li>
            <li>
                <b>Intellectual Property Rights.</b><br />
                <p>You acknowledge that you do not acquire any ownership interest in the Software under this Agreement, or any other rights to the Software other than to use the Software in accordance with the licence granted under this Agreement, subject to all terms, conditions, and restrictions. Licensor and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Software and all intellectual property rights arising out of or relating to the Software, subject to the licence expressly granted to the Licensee in this Agreement. You shall safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse, or unauthorized access.</p>
            </li>
            <li>
                <b>Disclaimer of Liability.</b><br />
                <p>IN NO EVENT WILL LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE SOFTWARE. YOU ARE PROVIDED THE SOFTWARE UNDER THE SOFTWARE LICENCE AGREEMENT BETWEEN LICENSOR AND LICENSEE, SOLELY FOR THE BENEFIT OF LICENSEE AND AT LICENSEE'S DISCRETION. YOU ACKNOWLEDGE THAT YOU HAVE NO RIGHTS UNDER THAT AGREEMENT INCLUDING ANY RIGHTS TO ENFORCE ANY OF ITS TERMS. ANY OBLIGATION OR LIABILITY LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR LICENSORS OR SERVICE PROVIDERS, MAY HAVE WITH RESPECT TO YOUR USE OR INABILITY TO USE THE SOFTWARE SHALL BE SOLELY TO LICENSEE UNDER THAT AGREEMENT AND SUBJECT TO ALL LIMITATIONS OF LIABILITY SET FORTH THEREIN.</p>
            </li>
            <li>
                <b>Export Regulation.</b><br />
                <p>The Software may be subject to Canadian export control laws. You shall not, directly or indirectly, export, re-export, or release the Software to, or make the Software or Documentation accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. You shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export licence or other governmental approval), before exporting, re-exporting, releasing, or otherwise making the Software available outside Canada.</p>
            </li>
            <li>
                <b>Governing Law</b><br />
                <p>These Terms of Use are governed by and construed in accordance with the laws of the Province of Alberta and the federal laws of Canada applicable therein.</p>
            </li>
        </ol>
    </Terms>
)

const ScreenAcceptTerms = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const user = useSelector(selectUser);

    const onCancel = async () => {
        await dispatch(logout());
        nav('/')
    }

    const onAccept = async () => {
        await dispatch(acceptTerms(user.email))
        nav('/')
    }

    const {
        control,
        formState: { errors, isValid, isSubmitting },
        handleSubmit
    } = useForm();

    const fieldProps = { control, errors, rules: { required: true } };

    return (
        <>
            <ViewHeader>
                <Logo src={logoImg} />
            </ViewHeader>
            <Container>
                <H1>Terms of Use</H1>
                <TermsOfUse user={user} />
                <Row style={{ flex: 0 }}>
                    <FormCheckbox name="accepted" {...fieldProps} placeholder="I have read and accept the terms of use" />
                </Row>
                <Divider />
                <Row style={{ flex: 0 }}>
                    <BlueButton onClick={handleSubmit(onAccept)} disabled={!isValid || isSubmitting}>Accept Terms</BlueButton>
                    <BlueButton onClick={handleSubmit(onCancel)} disabled={isSubmitting}>Cancel</BlueButton>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default ScreenAcceptTerms;
