import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { createUserEmergencyContacts, fetchUserEmergencyContacts, selectActiveUser, selectUserEmergencyContacts } from '../common/slices/user';
import theme from '../common/theme';
import { ButtonBase, RedButton } from "./Buttons";
import Divider from "./Divider";
import FormERP from "./FormERP";
import FormTextInput from "./FormTextInput";
import Modal from './Modal';
import { H1, P } from "./Typography";
import FormEmergencyContact from "./FormEmergencyContact";
import { useEffect, useState } from "react";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`

const unmarshall = (values) => {
    return values?.contacts.map(({ notifyBy, ...c }) => c);
}

const ModalEmergencyContacts = ({ show, onClose }) => {
    const [loading, setLoading] = useState(true);
    const activeUser = useSelector(selectActiveUser);
    const contacts = useSelector(selectUserEmergencyContacts);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (activeUser) {
                setLoading(true);
                await dispatch(fetchUserEmergencyContacts(activeUser.email));
                setLoading(false);
            }
        })()
    }, [activeUser])

    const onSaveClick = async (values) => {
        await dispatch(createUserEmergencyContacts(activeUser.email, unmarshall(values)));
        onClose();
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm({
        values: {
            contacts
        }
    })

    const fieldProps = { control, errors };

    return (
        <Modal
            show={show}
            onClose={onClose}
        >
            <Container>
                <Header>
                    <H1>Manage Emergency Contacts</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                {
                    !loading &&
                    <FormEmergencyContact name="contacts" {...fieldProps} />
                }
                {
                    loading && <P>Loading...</P>
                }
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</RedButton>
                    <ButtonBase onClick={onClose} disabled={isSubmitting} >Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalEmergencyContacts